.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* src/index.css */
@import './styles/fonts.css';

/* Ensure default cursor for the body and all elements */
body, * {
  /*font-family: "retro2";
  /*font-size: 22px; /* Change to desired default size */

  cursor: url('./images/Mouse.png'), auto;
}

/* Change cursor on hover for all elements */
body *:hover {
  cursor: url('./images/Mouse.png'), auto;
}
